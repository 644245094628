import { Clerk } from "@clerk/clerk-js"
import * as util from "./util"
import * as utillogin from "./utillogin"

/* To locally debug this script via Webflow the best option I've found is to
add two scripts in Webflow:
<script src="http://localhost:3000/????.bundle.js"></script>
<script src="angry-boyd-1669aa.netlify.app/????.bundle.js" crossorigin="anonymous"></script>
The below check ensures that if localhost loads successfully the second
(production) script won't run.
Initially I tried to add the script dynamically in Webflow but that didn't
work - I needed it to run immediately but it wouldn't. */
if (typeof SharesyWebflow === "undefined") {
  util.initSentry()

  window.amplitudeOnLoad = function (instance) {
    util.amplitudeInit(instance, "Dashboard Login Viewed")
  }

  // Wrap everything with Revealing Module Pattern
  window.SharesyWebflow = (function () {
    const clerk = new Clerk(utillogin.CLERK_PUBLISHABLE_KEY)
    async function initClerk() {
      await clerk.load()
      clerk.mountSignIn(document.getElementById("sign-in"), {
        appearance: {
          elements: {
            formButtonPrimary: {
              textTransform: "none",
              fontSize: "14px",
              backgroundColor: "#1aa979",
              "&:hover, &:focus, &:active": {
                backgroundColor: "#24b886",
              },
            },
            formFieldAction: {
              color: "#1aa979",
              "&:hover, &:focus, &:active": {
                color: "#24b886",
              },
            },
            identityPreviewEditButton: {
              color: "#1aa979",
              "&:hover, &:focus, &:active": {
                color: "#24b886",
              },
            },
            headerBackIcon: {
              color: "#1aa979",
              "&:hover, &:focus, &:active": {
                color: "#24b886",
              },
            },
            headerBackLink: {
              color: "#1aa979",
              "&:hover, &:focus, &:active": {
                color: "#24b886",
              },
            },
            formResendCodeLink: {
              color: "#1aa979",
              "&:hover, &:focus, &:active": {
                color: "#24b886",
              },
            },
            footer: {
              display: "none",
            },
          },
        },
      })
    }

    function init() {
      initClerk()
    }

    return {
      init: init,
    }
  })()
}
