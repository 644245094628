import { Clerk } from "@clerk/clerk-js"

export const CLERK_PUBLISHABLE_KEY = "pk_live_Y2xlcmsuc2hhcmVzeS5jb20k"
var clerk

async function clerkLogin() {
  let isClerk = true
  if (!clerk) {
    isClerk = false
    clerk = new Clerk(CLERK_PUBLISHABLE_KEY)
  }
  if (!clerk.loaded) {
    await clerk.load({
      signInUrl: "/login",
    })
  }
  if (!clerk.session) {
    clerk.redirectToSignIn()
  }
  if (!isClerk) {
    const userButtonComponent = document.getElementById("user-button")
    clerk.mountUserButton(userButtonComponent, {
      afterSignOutUrl: "/login",
      appearance: {
        userProfile: {
          elements: {
            profileSectionPrimaryButton__emailAddresses: {
              display: "none",
            },
          },
        },
      },
    })
  }
}

export async function getClerkToken() {
  await clerkLogin()
  return await clerk.session.getToken()
}

export async function getClerkUserIsAdmin() {
  await clerkLogin()
  try {
    return clerk.user.publicMetadata.is_admin === "true"
  } catch (err) {
    return false
  }
}

export async function isClerkFeatureEnabled(feature) {
  await clerkLogin()
  try {
    return clerk.user.publicMetadata[feature] === "enabled"
  } catch (err) {
    return false
  }
}

/**
 * Add Retool links to the top navigation. Don't add links to the hamburger
 * menu since our Retool apps don't display on mobile.
 */
export async function displayAdminNavItems() {
  if (await getClerkUserIsAdmin()) {
    let navLinks = document.getElementById("nav-links")
    let navOpeningHours = document.getElementById("nav-opening-hours")

    const newBooking = document.createElement("a")
    newBooking.href =
      "https://sharesy.retool.com/apps/abfc35a0-71c0-11ee-bd2d-fb126ee55298/New%20Booking"
    newBooking.innerText = "New booking"
    newBooking.classList.add("header-link-narrow")
    navLinks.insertBefore(newBooking, navOpeningHours)

    const pricing = document.createElement("a")
    pricing.href =
      "https://sharesy.retool.com/apps/6b74c1f2-7353-11ee-824f-8f45ff89b58b/Pricing"
    pricing.innerText = "Pricing"
    pricing.classList.add("header-link-narrow")
    navLinks.insertBefore(pricing, navOpeningHours)

    const commission = document.createElement("a")
    commission.href =
      "https://sharesy.retool.com/apps/f8e776e2-87e8-11ef-a73f-d77d19ebba2c/Commission"
    commission.innerText = "Commission"
    commission.classList.add("header-link-narrow")
    navLinks.insertBefore(commission, navOpeningHours)

    const sbAdmin = document.createElement("a")
    sbAdmin.href =
      "https://sharesy.retool.com/apps/019eac16-3a6a-11ed-81db-af9e81b0ce45/Transaction%20Dashboard"
    sbAdmin.innerText = "Emails + SB Admin"
    sbAdmin.classList.add("header-link-narrow")
    // insert after opening hours
    navLinks.insertBefore(sbAdmin, navOpeningHours.nextSibling)

    const users = document.createElement("a")
    users.href =
      "https://sharesy.retool.com/apps/b2c0fa4e-dfc8-11ee-a8be-e79d9f1911df/Users"
    users.innerText = "Users"
    users.classList.add("header-link-narrow")
    navLinks.insertBefore(users, navOpeningHours.nextSibling)
  }
}
